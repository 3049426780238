import { graphql } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';

import Collage from '../components/collage';
import DisplayToggles from '../components/displayToggles';
import Gallery from '../components/gallery';
import Layout from '../components/layout';
import List from '../components/list';
import { Seo } from '../components/seo';

const SeePage = ({ data }) => {
  const [layout, setLayout] = useState('list');

  const [collageHovered, setCollageHovered] = useState(false);
  const [galleryHovered, setGalleryHovered] = useState(false);
  const [listHovered, setListHovered] = useState(false);

  return (
    <Layout navbarTheme={listHovered ? 'grayLight' : 'grayDark'}>
      <main className="relative min-h-screen min-w-screen py-16 px-8 flex flex-col items-center justify-center">
        {layout === 'collage' ? (
          <Collage
            entries={data.allContentfulSee.nodes}
            hovered={collageHovered}
            setHovered={setCollageHovered}
          />
        ) : layout === 'gallery' ? (
          <Gallery
            entries={data.allContentfulSee.nodes}
            hovered={galleryHovered}
            setHovered={setGalleryHovered}
          />
        ) : (
          <List
            entries={data.allContentfulSee.nodes}
            hovered={listHovered}
            setHovered={setListHovered}
          />
        )}
      </main>

      <DisplayToggles
        layout={layout}
        setLayout={setLayout}
        listHovered={listHovered}
      />
    </Layout>
  );
};

export default SeePage;

export const Head = ({ data }) => (
  <Seo
    title={data.contentfulGenericPage.meta.title}
    description={data.contentfulGenericPage.meta.description}
  />
);

export const query = graphql`
  {
    contentfulGenericPage(contentful_id: { eq: "4kE629QIlzC95uYGkD19bG" }) {
      meta {
        title
        description
      }
    }
    allContentfulSee(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        title
        slug
        highlightColor
        featuredImage {
          gatsbyImage(width: 640)
          publicUrl
        }
      }
    }
  }
`;
