import React, {useState} from "react"
import {Link} from "gatsby";
import joinClassNames from "../utilities/joinClassNames";

export default function List({ entries, hovered, setHovered }) {

  const [highlightColor, setHighlightColor] = useState('');

  return (
    <>
      <div
        className={joinClassNames(
          hovered ? 'opacity-100' : 'opacity-0',
          `bg-${highlightColor}`,
          'absolute inset-0 z-0 transition-opacity pointer-events-none'
        )}
      />

      <div
           className={joinClassNames(
             hovered && (highlightColor === 'black' || highlightColor === 'gray') ? 'text-white' : 'text-black',
             'container mx-auto text-xl md:text-3xl lg:text-6xl leading-snug z-10'
           )}>
        {entries.map((entry, index) => (
          <React.Fragment key={index}>
            <Link
              className={`font-bold hover:font-light cursor-${highlightColor}`}
              to={entry.slug}
              onMouseEnter={() => {
                setHighlightColor(entry.highlightColor.toLowerCase())
                if (entry.highlightColor !== 'White') {
                  setHovered(true)
                }
              }}
              onMouseLeave={() => setHovered(false)}
            >
              {entry.title}
            </Link>
            {index !== entries.length - 1 && (
              <>{` `}<span className="inline-block mx-4">/</span>{` `}</>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  )
}