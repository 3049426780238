import React, {useState} from "react"
import {Link} from "gatsby";

import joinClassNames from '../utilities/joinClassNames';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import {GatsbyImage} from "gatsby-plugin-image";

export default function Collage({ entries, hovered, setHovered }) {

  const [highlightColor, setHighlightColor] = useState('');

  return (
    <>
      <div
        className={joinClassNames(
          hovered ? 'opacity-100' : 'opacity-0',
          `bg-${highlightColor}`,
          'absolute inset-0 z-0 transition-opacity pointer-events-none'
        )}
      />

      <ResponsiveMasonry
        columnsCountBreakPoints={{ 0: 2, 640: 2, 1024: 3 }}
        className="w-full"
      >
        <Masonry>
          {entries.map((entry, index) => (
            <div className="w-full p-2 md:p-8 hover:z-20">
              <Link
                className={joinClassNames(
                  hovered ? 'opacity-50' : '',
                  `cursor-${highlightColor}`,
                  'block w-full hover:scale-150 transition-all transform-gpu hover:!opacity-100'
                )}
                to={entry.slug}
                key={index}
                onMouseEnter={() => {
                  setHighlightColor(entry.highlightColor.toLowerCase())
                  if (entry.highlightColor !== 'White') {
                    setHovered(true)
                  }
                }}
                onMouseLeave={() => setHovered(false)}
              >
                <GatsbyImage
                  alt={entry.title}
                  image={entry.featuredImage.gatsbyImage}
                />
              </Link>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </>
  )
}