import React from "react"
import joinClassNames from "../utilities/joinClassNames";
import IconCollageBlack from "../images/icon-collage-black.svg";
import IconCollageWhite from "../images/icon-collage-white.svg";
import IconListBlack from "../images/icon-list-black.svg";
import IconListWhite from "../images/icon-list-white.svg";
import IconGalleryBlack from "../images/icon-gallery-black.svg";
import IconGalleryWhite from "../images/icon-gallery-white.svg";

export default function DisplayToggles({ layout, setLayout, listHovered }) {
  return (
    <div className="fixed bottom-0 inset-x-0 h-16 px-8 flex items-center z-20">
      <div className="container mx-auto flex justify-between">
        <button
          className={joinClassNames(
            layout === 'collage' ? 'btn-outline-light-active' : '',
            listHovered ? 'btn-outline-dark' : 'btn-outline-light',
            'btn-outline'
          )}
          onClick={() => setLayout('collage')}
        >
          <div className="relative">
            <img
              src={IconCollageBlack}
              alt="Collage"
              className="h-3 mr-1 icon-black"
            />
            <img
              src={IconCollageWhite}
              alt="Collage"
              className="h-3 mr-1 icon-white absolute inset-0"
            />
          </div>
          <span className="text-right" style={{ minWidth: '4rem' }}>
              Collage
            </span>
        </button>
        <button
          className={joinClassNames(
            layout === 'list' ? 'btn-outline-light-active' : '',
            listHovered ? 'btn-outline-dark' : 'btn-outline-light',
            layout === 'list' && listHovered ? 'btn-outline-dark-active' : '',
            'btn-outline'
          )}
          onClick={() => setLayout('list')}
        >
          <div className="relative">
            <img
              src={IconListBlack}
              alt="List"
              className="h-3 mr-1 icon-black"
            />
            <img
              src={IconListWhite}
              alt="List"
              className="h-3 mr-1 icon-white absolute inset-0"
            />
          </div>
          <span className="text-right" style={{ minWidth: '4rem' }}>
              List
            </span>
        </button>
        <button
          className={joinClassNames(
            layout === 'gallery' ? 'btn-outline-light-active' : '',
            listHovered ? 'btn-outline-dark' : 'btn-outline-light',
            'btn-outline'
          )}
          onClick={() => setLayout('gallery')}
        >
          <div className="relative">
            <img
              src={IconGalleryBlack}
              alt="Gallery"
              className="h-3 mr-1 icon-black"
            />
            <img
              src={IconGalleryWhite}
              alt="Gallery"
              className="h-3 mr-1 icon-white absolute inset-0"
            />
          </div>
          <span className="text-right" style={{ minWidth: '4rem' }}>
              Gallery
            </span>
        </button>
      </div>
    </div>
  )
}