import React, {useRef, useState} from "react"
import {Link} from "gatsby";

import joinClassNames from '../utilities/joinClassNames';
import {GatsbyImage} from "gatsby-plugin-image";
import {Swiper, SwiperSlide} from "swiper/react";
import { Navigation } from 'swiper';
import IconDownBlack from "../images/icon-down-black.svg";

export default function Gallery({ entries, hovered, setHovered }) {

  const swiperRef = useRef();
  const [highlightColor, setHighlightColor] = useState('');

  return (
    <>
      <div
        className={joinClassNames(
          hovered ? 'opacity-100' : 'opacity-0',
          `bg-${highlightColor}`,
          'absolute inset-0 z-0 transition-opacity pointer-events-none'
        )}
      />

      <Swiper
        slidesPerView={1}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        }}
        className="relative w-full h-full grid flex-1"
      >
        {entries.map((entry, index) => (
          <SwiperSlide key={index}>
            <Link
              className={joinClassNames(
                hovered ? 'opacity-50' : '',
                `cursor-${highlightColor}`,
                'relative w-full h-full flex items-center text-center scale-75 hover:scale-100 hover:!opacity-100 transition-all transform-gpu gallery-content'
              )}
              to={entry.slug}
              onMouseEnter={() => {
                setHighlightColor(entry.highlightColor?.toLowerCase())
                if (entry.highlightColor !== 'White') {
                  setHovered(true)
                }
              }}
              onMouseLeave={() => setHovered(false)}
            >
              <GatsbyImage
                alt={entry.title}
                image={entry.featuredImage.gatsbyImage}
                className="absolute inset-0 z-0"
              />
              <h2 className={`z-10 w-full uppercase text-4xl font-bold text-${highlightColor} opacity-0 transition-opacity`}>
                {entry.title}
              </h2>
            </Link>
          </SwiperSlide>
        ))}

        <button className="absolute z-10 left-0 inset-y-0 cursor-pointer" onClick={() => swiperRef.current?.slidePrev()}>
          <img src={IconDownBlack} alt="Scroll Down" className="h-4 rotate-90" />
        </button>
        <button className="absolute z-10 right-0 inset-y-0 cursor-pointer" onClick={() => swiperRef.current?.slideNext()}>
          <img src={IconDownBlack} alt="Scroll Down" className="h-4 -rotate-90" />
        </button>
      </Swiper>
    </>
  )
}